.floating-footer {
    position: fixed;
    bottom: 12px;
    /* 20px up from the bottom */
    left: 50%;
    transform: translateX(-50%);
    /* background: transparent; */
    /* Changes background to transparent */
    background-color: rgba(244, 103, 122, 1);
    color: #ffffff;
    /* any color you prefer for text */
    text-align: center;
    padding: 4px 20px 0px 20px;
    border: 1px solid #ffffff;
    /* add a border */
    border-radius: 50px;
    /* add rounded corners */
    font-size: 13px;
    font-weight: 400;
    min-width: 260px;
}