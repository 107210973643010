/* VerticalBanner.css */
.vertical-banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.vertical-banner-text {
  transform: rotate(-90deg);
  white-space: nowrap;
  font-size: 0.75em;
}

/* HorizontalBanner.css */
.horizontal-banner {
  position: absolute;
  top: -10%;
  left: 0;
  width: 100%;
  height: 10%;
  min-height: 40px;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 1;
  transition: top 1.5s ease;
}

.horizontal-banner.show {
  top: 0;
}

.horizontal-banner .close-button {
  position: absolute;
  right: 20px;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  background-image: url('./assets/icons/x-mark.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.horizontal-banner-text {
  white-space: nowrap;
  font-size: 0.75em;
  overflow-x: hidden;
  text-align: center;
  width: 60%;
  position: relative;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

.horizontal-banner-text span.scroll {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 30s linear infinite;
}

.text-with-whitespace {
  white-space: pre;
}

.work-in-progress {
  backdrop-filter: blur(10px);
  background: repeating-linear-gradient(-45deg,
      rgb(255, 255, 0, 0.8) 2px,
      rgb(255, 255, 0, 0.8) 10px,
      rgb(70, 66, 0, 0.6) 10px,
      rgb(70, 66, 0, 0.6) 20px);
  width: 30px;
  height: 70%;
  top: 3%;
  left: 5%;
  position: absolute;
}