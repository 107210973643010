.calendar-container {
    position: relative;
}

.clickable {
    cursor: pointer;
}

.calendar-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 0 0;
    font-size: 32px;
    font-weight: 700;
    max-width: 90%;
    width: 90%;
}

.arrow {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 15%;
}

.arrow img {
    width: 35%;
}