.selected-date-details {
    position: absolute;
    bottom: 0;
    height: 0;
    width: 90%;
    background-color: rgba(244, 103, 122, 0.2);
    overflow: hidden;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.selected-date-details.slide-transition {
    transition: height 0.5s;
}

.selected-date-details.open {
    height: 30%;
}

.selected-date-details .close-button {
    position: absolute;
    top: 15px;
    right: 20px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    background-image: url('./assets/icons/x-mark.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.selected-date-details .date-details-wrapper {
    display: flex;
    margin: 30px;
}

.date-details-wrapper .calendar-icon {
    flex: 0.25;
}

.date-details-wrapper .details-content {
    flex: 0.75;
}

.calendar-icon {
    border: 3px solid rgba(244, 103, 122);
    height: 80px;
    max-width: 80px;
    text-align: center;
    border-radius: 5px;
}

.details-content {
    padding: 0px 20px;
    max-height: 132px;
    overflow-y: auto;
}

.calendar-icon .month {
    background-color: rgba(244, 103, 122);
    color: #fff;
    padding: 2px;
}

.calendar-icon .date {
    font-size: 24px;
    font-weight: 700;
}

/* Scollbar style Begins*/
/* For Chrome, Safari and Opera */
.details-content::-webkit-scrollbar {
    width: 5px;
}

.details-content::-webkit-scrollbar-track:hover {
    background-color: rgba(244, 103, 122, 0.2);
    box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.1);
    border-radius: 0px;
}

.details-content::-webkit-scrollbar-thumb {
    background-color: rgba(244, 103, 122, 0.2);
    border-radius: 0px;
}

.details-content::-webkit-scrollbar-thumb:hover {
    background-color: rgba(244, 103, 122, 0.5);
}

/* For IE and Edge */
.details-content {
    -ms-overflow-style: none;
    /* IE and Edge */
}

.details-content::-ms-overflow-style {
    display: none;
    /* IE and Edge */
}

/* Scollbar style Ends*/
