.moon {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 10px;
}

.light {
    position: absolute;
    background: #fff;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: scale(0.98);
}

.shadow {
    position: absolute;
    background: #ee6174;
    width: 200%;
    height: 200%;
    border-radius: 50%;
    top: -50%;
    left: -50%;
    transform: translate(0%, 0%);
}

.new-moon .shadow {
    transform: translate(0%, 0%);
}

.waxing-crescent .shadow {
    transform: translate(-20%, -30%);
}

.first-quarter .shadow {
    transform: translate(-50%, 0%);
}

.waxing-gibbous .shadow {
    transform: translate(-50%, 30%);
}

.full-moon .shadow {
    transform: translate(80%, 0%);
}

.waning-gibbous .shadow {
    transform: translate(50%, -30%);
}

.last-quarter .shadow {
    transform: translate(50%, 0%);
}

.waning-crescent .shadow {
    transform: translate(20%, 30%);
}
