.current-date-view {
    position: relative;
    /*background-image: url('./assets/images/raja-3.png');*/
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 400px 400px;
}

.the-label {
    text-align: left;
    font-size: 1em;
}

.subsequent-label {
    margin-top: 3%;
}