.upcoming-days-container {
    width: 90%;
}

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
    margin: 3% 0;
}

.card {
    flex: 1 1 auto;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    color: rgb(185, 69, 84);
    overflow: hidden;
    position: relative;
}

.card .the-date-in-background {
    position: absolute;
    top: -10px;
    right: -5px;
    font-size: 4em;
    font-weight: bolder;
    pointer-events: none;
    color: rgb(255, 255, 255, 0.4);
}

.card .the-day-in-background {
    position: absolute;
    top: -20px;
    left: -5px;
    font-size: 2em;
    pointer-events: none;
    color: rgb(255, 255, 255, 0.3);
}

.card .the-date-details {
    position: relative;
    z-index: 1;
    padding-top: 3px;
    margin-bottom: -4px;
}

.cards .month-change {
    text-align: left;
    margin: 0 0 -6px 5px;
    font-size: 0.9em;
}

.card.special-event {
    background-color: rgba(255, 223, 186, 0.7);
    box-shadow: 0px 0px 5px rgba(255, 174, 0, 0.9);
}

.card.special-event .special-event-details .special-mention {
    position: relative;
    z-index: 1;
    font-size: 1.2em;
    margin: -1px 0 -7px 0;
}