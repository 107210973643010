.important-day-header {
    font-size: 32px;
    font-weight: 700;
    padding: 10px 0 0 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.important-day-details-container {
    max-height: 75vh;
    min-height: 75vh;
    overflow: auto;
}

.transparent-div {
    display: flex;
    background-color: rgba(244, 103, 122, 0.5);
    justify-content: center;
    /* This is the background with 50% opacity. Adjust as needed */
}

.flex-column {
    flex: 1;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.date-column {
    /* display: flex; */
    align-items: center;
    /* justify-content: center; */
    max-width: 20%;
}

.description-column {
    text-align: left;
    max-width: 80%;
}

.filter-menu-container {
    display: flex;
    justify-content: space-around;
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    gap: 15px;
}

.filter-menu-container .filter-menu {
    border: 1px solid rgba(255, 255, 255, 1);
    border-radius: 50px;
    font-size: 13px;
    font-weight: 400;
    padding: 4px 20px 0px 20px;
    cursor: pointer;
    white-space: nowrap;
}

.filter-menu-container .filter-menu:not(.selected):hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.filter-menu-container .selected {
    cursor: default;
    color: #f4677a;
    background-color: rgba(255, 255, 255, 1);
}

.no-days-available {
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('./assets/icons/no-events.svg');
    background-size: cover;
    background-position: center;
    opacity: 0.5;
    margin-top: 30%;
}

/* Scollbar style Begins*/
/* For Chrome, Safari and Opera */
.important-day-details-container::-webkit-scrollbar {
    width: 10px;
}

.important-day-details-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f4677a;
    border-radius: 0px;
}

.important-day-details-container::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 0px;
}

.important-day-details-container::-webkit-scrollbar-thumb:hover {
    background-color: #ffffff;
}

/* For IE and Edge */
.important-day-details-container {
    -ms-overflow-style: none;
    /* IE and Edge */
}

.important-day-details-container::-ms-overflow-style {
    display: none;
    /* IE and Edge */
}

/* Scollbar style Ends*/